export default {
    url: null,
    socket: null,
    nomJoueur: '',
    onMessageCallback: null,
    onRawMessageCallback: null,
    onOpenCallback: null,
    onDisconnectCallback: null,
    mustTerminate: false,
    reconnectInterval: null,

    connect(url, nomJoueur, onMessageCallback, onRawMessageCallback, onOpenCallback, onDisconnectCallback) {
        if (this.socket !== null)
            this.reset();

        this.url = url;

        if (nomJoueur.length > 40)
            nomJoueur = nomJoueur.substr(0, 40);

        this.nomJoueur = nomJoueur;
        this.onMessageCallback = onMessageCallback;
        this.onRawMessageCallback = onRawMessageCallback;
        this.onOpenCallback = onOpenCallback;
        this.onDisconnectCallback = onDisconnectCallback;

        this._connect();
        window.zprub = this._disconnect_and_clear.bind(this);
    },

    _connect() {
        if (this.url === null) return;

        if (this.socket !== null)
            this.reset();

        this.socket = new WebSocket(this.url);
        this.socket.onerror = this.onError.bind(this);
        this.socket.onopen = this.onOpen.bind(this);
        this.socket.onclose = this.onClose.bind(this);
        this.socket.onmessage = this.onMessage.bind(this);

        window.zprubi = function() {
            this.socket.close();
        }.bind(this);
    },

    onError(erreur) {
        console.log('onError =>', erreur);
        this.onDisconnectCallback();
        this._disconnect_and_clear();
    },

    close(explicit=true,disconnectAndClear=true) {
        console.info('CLOSE EXPLICIT CALLED');
        this.mustTerminate = explicit;

        if (this.socket !== null)
            this.socket.close();

        if (this.onDisconnectCallback !== null)
            this.onDisconnectCallback();

        if (disconnectAndClear)
            this._disconnect_and_clear();
    },

    reset() {
        if (this.reconnectInterval !== null) {
            clearTimeout(this.reconnectInterval);
            this.reconnectInterval = null;
        }

        this.url = null;

        if (this.socket !== null) {
            this.socket.onerror = null;
            this.socket.onopen = null;
            this.socket.onclose = null;
            this.socket.onmessage = null;
            this.socket.close();
        }
        this.socket = null;

        this.nomJoueur = '';
        this.onMessageCallback = null;
        this.onRawMessageCallback = null;
        this.onOpenCallback = null;
        this.onDisconnectCallback = null;
        this.mustTerminate = false;
    },

    onOpen(event) {
        console.log('onOpen =>', event);

        this.socket.send(JSON.stringify({
            msg_type: 'auth',
            msg_data: {
                nom_joueur: this.nomJoueur,
            },
        }));
        this.onOpenCallback();
    },

    onClose(event) {
        console.log('onClose =>', event);
        this.onDisconnectCallback(event);
        this._disconnect_and_clear();
    },

    onMessage(e) {
        //console.log('onMessage =>', e);

        if (e.data instanceof Blob) {
            this.onRawMessageCallback(e.data);
        } else {
            const decodedMsg = JSON.parse(e.data);

            if (decodedMsg.msg_type === '_ws_challenge') {
                this.send('_ws_alive', decodedMsg.msg_data);
            } else {
                this.onMessageCallback(decodedMsg.msg_type, decodedMsg.msg_data);
            }
        }
    },

    send(msg_type, msg_data) {
        if (this.socket === undefined || this.socket === null) return;

        let msg = {
            msg_type: msg_type,
        };

        if (msg_data !== undefined) {

            if (msg_type === 'chatbox_msg' && typeof msg_data === 'string') {
                if (msg_data.length > 135) {
                    msg_data = msg_data.substr(0, 135);
                }
            }

            if (msg_type === 'e1_mot_custom' && typeof msg_data === 'string') {
                if (msg_data.length > 256) {
                    msg_data = msg_data.substr(0, 256);
                }
            }

            if (msg_type === 'e3_mot' && typeof msg_data === 'string') {
                if (msg_data.length > 256) {
                    msg_data = msg_data.substr(0, 256);
                }
            }

            msg.msg_data = msg_data;
        }

        this.socket.send(JSON.stringify(msg));
    },

    sendRaw(data) {
        this.socket.send(data);
    },

    _disconnect_and_clear() {
        if (this.socket !== null) {
            this.socket.onerror = null;
            this.socket.onopen = null;
            this.socket.onclose = null;
            this.socket.onmessage = null;
            this.socket.close();
            this.socket = null;
        }
        if (this.onDisconnectCallback !== null)
            this.onDisconnectCallback();

        console.log('disconnect and clear');

        console.log('this.mustTerminate =', this.mustTerminate);
        if (!this.mustTerminate) {
            if (this.reconnectInterval !== null) {
                clearTimeout(this.reconnectInterval);
                this.reconnectInterval = null;
            }

            this.reconnectInterval = setTimeout(this._connect.bind(this), 600);
        }
    },
};
