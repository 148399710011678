<template>
  <div style="width: 100%;" class="has-text-centered">

    <SoundManager ref="soundManager"/>

    <NotificationRejoin @rejoin="revenirEnJeu" ref="notificationRejoin"/>

    <span class="tag is-danger" v-if="!ws_connected && this.salon_etat !== null">
      {{ $t('sketchy.global.reconnect') }}
    </span>

    <div
      v-if="salon_etat === 'e0' && infosSalon !== null"
      class="has-text-centered"
      style="margin-bottom: 9px;"
    >
      <h1 class="title" style="font-family:cabin_sketch_bold;">
        {{ infosSalon.nom_salon }}
        <DrapeauFr v-if="infosSalon.langue === 'fr'" class="drapeau"/>
        <DrapeauUsaUk v-if="infosSalon.langue === 'en'" class="drapeau"/>
      </h1>
    </div>

    <div class="columns is-fluid is-centered is-marginless is-paddingless" style="max-width: 100%;">

      <div class="column is-narrow">

        <PlayerList
          style="margin-top: 8px;"
          :class="{'is-hidden': salon_etat === null}"

          ref="playerList"

          @sendMsg="sendMsg"
          @chatboxPush="chatboxPush"
          @interact="interact"
          @soundReady="soundReady"

          :nom_joueur="nom_joueur"
          :index_joueur="index_joueur"
          :salon_etat="salon_etat"
          :id_salon="$route.params.salon"
        />

        <div v-if="salon_etat === 'e0'" style="font-family: cabin_sketch_regular;color: #999;font-size: 14px;">
          {{ $t('sketchy.page_jeu.etat_0.duree_partie') }}{{ $t(':') }}
          {{ $t('sketchy.page_jeu.etat_0.duree_partie_environ') }}

          <span>
            <template v-if="duree_partie_minutes > 0 && duree_partie_secs > 0">
              {{ $t('sketchy.page_jeu.etat_0.duree_partie__m_s', [duree_partie_minutes, duree_partie_secs]) }}
            </template>
            <template v-else-if="duree_partie_minutes > 0">
              {{ $t('sketchy.page_jeu.etat_0.duree_partie__m', [duree_partie_minutes]) }}
            </template>
            <template v-else-if="duree_partie_secs > 0">
              {{ $t('sketchy.page_jeu.etat_0.duree_partie__s', [duree_partie_secs]) }}
            </template>
          </span>
        </div>

        <E0BoxPartageLien
          v-if="salon_etat === 'e0'"
          class="e0_box_partage_lien"
          :id_salon="$route.params.salon"
        />
      </div>

      <div v-if="salon_etat === 'e0'" class="has-text-centered">
        <div style="display: inline-block;text-align: center;max-width: 300px;">
          <Chatbox
            :messages_chatbox="messages_chatbox"
            :salon_etat="salon_etat"
            :flag_e3_mot_ok="flag_e3_mot_ok"
            @sendMsg="sendMsg"
          />
        </div>
        <br/>

        <E0GameOptions
          style="display: inline-block;"
          class="e0_box_options"
          :nb_joueurs="nb_joueurs"
          :isHost="isHost"
          :salon_langue="infosSalon.langue"
          @sendMsg="sendMsg"
          ref="gameOptions"
        />
      </div>

      <div class="salon-container column" v-if="salon_etat !== 'e0'">
        <div class="salon-block zone-millieu">

          <BarreRounds
            v-if="rounds_cur !== null && rounds_total !== null && rounds_total > 1 && (salon_etat === 'e2' || salon_etat === 'e3')"
            style="width: 100%;padding-left: 2px;padding-right: 2px;"
            :salon_etat="salon_etat"
            :rounds_cur="rounds_cur"
            :rounds_total="rounds_total"
          />

          <Etat_NonConnecte
            v-if="salon_etat === null"
            @connect="connect"
            @rejoin="revenirEnJeu"
            @sendMsg="sendMsg"
            @interact="interact"
            :waitPassword="waitPassword"
            :passInvalid="passInvalid"
            :userExist="userExist"
            :connexionEnCours="ws_connexion_en_cours"
            :salon_id="$route.params.salon"
          />
          <Etat1AttenteMot
            v-else-if="salon_etat === 'e1'"
            class="e1_attente_mot"
            @sendMsg="sendMsg"
            :e1_mots="e1_mots"
            :nb_joueurs="nb_joueurs"
            :next_joueur_nom="nextJoueurNom"
            :gameOptions="gameOptions"
            @lastMotChoisi="lastMotChoisi = $event"
            ref="e1AttenteMot"
          />
          <Etat2AttenteDessin
            v-else-if="salon_etat === 'e2'"
            @dessinFini="e2DessinFini"
            :e2_countdown="e2_countdown"
            ref="e2"
          />
          <Etat3DevinerMot
            v-else-if="salon_etat === 'e3'"
            :e3_auteur="e3_auteur"
            :e3_image="e3_image"
          />
          <Etat4Fin
            v-else-if="salon_etat === 'e4'"
            @sendMsg="sendMsg"
            ref="etat4Fin"
            :id_salon="$route.params.salon"
            :e4_mots="e4_mots"
            :e4_auteurs="e4_auteurs"
            :e4_mots_auteurs="e4_mots_auteurs"
            :e4_mots_scores="e4_mots_scores"
            :gameOptions="gameOptions"
          />
        </div>

        <div class="salon-block zone-droite small" v-if="salon_etat === 'e1'||salon_etat === 'e4'">
          <Chatbox
            :messages_chatbox="messages_chatbox"
            :salon_etat="salon_etat"
            :flag_e3_mot_ok="flag_e3_mot_ok"
            @sendMsg="sendMsg"
          />

          <div
            v-if="salon_etat === 'e4'"
            class="dl-game"
            :class="{
              'frosted-background-static': $isSafari,
              'frosted-background': !$isSafari,
            }"
          >
            <div class="has-text-centered" style="font-family: cabin_sketch_regular; font-size: 20px">
              {{ $t("sketchy.page_jeu.etat_4.dl_game") }}
            </div>

            <a
              :href="$url_serveur + '/salon/' + $route.params.salon + '/export'"
              @click="exportHtmlClicked"
              download
            >
              <BoutonDl class="btn-dl"/>
            </a>
          </div>
        </div>

        <div class="salon-block zone-droite small e2" v-if="salon_etat === 'e2'">
          <E2Timer :e2_countdown="e2_countdown" :e2_mot="e2_mot"/>
        </div>

        <div class="salon-block zone-droite" v-if="salon_etat === 'e3'">
          <E3DevinerMotFormulaire @sendMsg="sendMsg" ref="e3DevinerMotFormulaire"/>
          <br/>
          <Chatbox
            :messages_chatbox="messages_chatbox"
            :salon_etat="salon_etat"
            :flag_e3_mot_ok="flag_e3_mot_ok"
            @sendMsg="sendMsg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/resources/libraries/bulma-0.9.0/sass/utilities/_all.sass';

.e0_box_options {
  @include mobile {
    margin-bottom: 30px;
  }
}

.e0_box_partage_lien {
  display: inline-block;
  width: 420px;
  max-width: 90vw;
}

.e1_attente_mot {
  @include desktop {
    margin-right: 40px;
  }

  @include widescreen {
    margin-right: 80px;
  }

  @include fullhd {
    margin-right: 130px;
  }
}

.dl-game {
  margin-top: 20px;
  border-radius: 12px;

  width: 300px;
  //height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;

  .btn-dl {
    margin: 10px;
    height: 50px;
    width: auto;
    display: inline-block;
  }
}

.drapeau {
  height: 20px;
  width: 30px;

  margin-left: 3px;
}

.salon-container {
  text-align: center;

  .salon-block {
    display: inline-block;
    vertical-align: top;

    &.zone-gauche {
      margin-left: 12px;
      margin-right: 12px;
      margin-bottom: 16px;
      text-align: left;
    }

    &.zone-millieu {
      text-align: left;
      margin-top: 16px;

      @include mobile {
        width: 100%;
      }
    }

    &.zone-droite {
      text-align: left;
      margin-left: 20px;

      &.small {
        max-width: 240px;

        @include mobile {
          max-width: none;
        }

        &.e2 {
          @include fullhd {
            max-width: 320px;
          }
        }
      }
    }
  }
}
</style>

<script>
import sketchy_ws from '@/resources/js/custom/sketchy_ws';

const LOCAL_STORAGE_E2_DESSIN = 'sketchy_e2_dessin';

export default {
  name: 'Salon',
  data() {
    return {
      nb_parties: 0,

      ws_connexion_en_cours: false,
      ws_connected: false,

      nom_joueur: null,
      index_joueur: null,
      salon_etat: null,
      waitPassword: false,
      passInvalid: false,
      userExist: false,
      // salon_etat: 'e4',

      e1_mots: null,
      e2_mot: null,
      e2_expected_countdown: null,
      flag_e2_dispatch_countdown: false,
      e2_countdown: null,
      e2_dessin: null,
      e3_image: null,
      e3_auteur: null,
      flag_e3_mot_ok: false,
      e4_mots: null/*[
        ['tomate', 'tomato',],
        ['salade', 'salado',],
      ]*/,

      e4_auteurs: null,//[['Jean-Claude Rotatof', 'Jean-Claude Rotatof'], ['François Bermute', 'Jean-Claude Rotatof']],
      e4_mots_auteurs: null,
      e4_mots_scores: null,

      e2_countdown_interval: null,

      new_message: "",
      messages_chatbox: [],

      nb_joueurs: 0,

      gameOptions: {},

      lastMotChoisi: null,
      infosSalon: null,

      rounds_cur: null,
      rounds_total: null,

      play_sound_connect: false,
    };
  },

  created() {
    if ('_sketchy_pseudo' in window && window._sketchy_pseudo !== null && window._sketchy_pseudo !== "") {
      this.tryConnect();
    }
  },

  beforeDestroy() {
    sketchy_ws.close();
    if (this.salon_etat === 'e0' || this.salon_etat === 'e1' || this.salon_etat === 'e2' || this.salon_etat === 'e3' || this.salon_etat === 'e4') {
      this.soundReady(-3);
    }
  },

  methods: {
    interact() {
      this.$refs.soundManager.interact();
    },

    revenirEnJeu() {
      this.interact();

      const idSalon = this.$session.data._last_salon;
      window._sketchy_pseudo = this.$session.data._last_pseudo;
      window._sketchy_password = this.$session.data._last_password;

      if (idSalon !== this.$route.params.salon)
        this.$router.push({name: 'Salon', params: {salon: idSalon}});

      this.$refs.notificationRejoin.clear();
      this.resetState();
      this.$nextTick(this.tryConnect);

      //this._matomo_trackGoal(this.MTM_GOAL_GAME_RECONNECT);
    },

    resetState() {
      sketchy_ws.reset();
      this.ws_connexion_en_cours = false;
      this.ws_connected = false;

      this.nom_joueur = null;
      this.salon_etat = null;
      this.waitPassword = false;
      this.passInvalid = false;
      this.userExist = false;

      this.e1_mots = null;
      this.e2_mot = null;
      this.e2_expected_countdown = null;
      this.flag_e2_dispatch_countdown = false;
      this.e2_countdown = null;
      this.e2_dessin = null;
      this.e3_image = null;
      this.e3_auteur = null;
      this.flag_e3_mot_ok = false;
      this.e4_mots = null;

      this.e4_auteurs = null;
      this.e4_mots_auteurs = null;
      this.e4_mots_scores = null;

      if (this.e2_countdown_interval !== null)
        clearInterval(this.e2_countdown_interval);

      this.e2_countdown_interval = null;

      this.new_message = "";
      this.messages_chatbox = [];

      this.nb_joueurs = 0;
      this.lastMotChoisi = null;

      this.infosSalon = null;
      this.play_sound_connect = false;
    },

    tryConnect() {
      console.log('try connect');
      if ('playerList' in this.$refs && this.$refs.playerList !== undefined)
        this.connect(window._sketchy_pseudo);
      else
        setTimeout(this.tryConnect, 25);
    },

    connect(nom_joueur) {
      if (this.ws_connexion_en_cours) return;
      sketchy_ws.close(false, false);
      this.ws_connexion_en_cours = true;

      this.$session._last_password = undefined;
      this.$axios.get(this.$url_serveur + '/salon/' + this.$route.params.salon + '?existe')
        .then((resp) => {
          console.info('RES CHECK SALON EXISTE =>', resp);
          this.infosSalon = resp.data;

          sketchy_ws.connect(this.$url_serveur_ws + '/salon/' + this.$route.params.salon, nom_joueur, this.onMessage, this.onRawMessage, () => {
            if ('playerList' in this.$refs && this.$refs.playerList !== undefined) {
              this.$refs.playerList.reset();
            }

            this.nom_joueur = nom_joueur;

            if ('_sketchy_password' in window && window._sketchy_password !== undefined && window._sketchy_password !== null && window._sketchy_password !== "") {
              this.sendMsg('wait_password', window._sketchy_password);
            }

            this.ws_connexion_en_cours = false;
            this.ws_connected = true;

            if (this.salon_etat === 'e2' && this.e2_countdown >= 0) {
              if (this.e2_countdown_interval !== null) {
                clearInterval(this.e2_countdown_interval);
                this.e2_countdown_interval = null;
              }

              this.e2CountdownStart(this.e2_countdown);
            }
          }, (event) => {
            if (event !== undefined && event !== null && 'code' in event) {
              if (event.code === 4404 || event.code === 4405 /*salon inexistant*/) {
                this.$session._last_salon = undefined;
                this.$session._last_pseudo = undefined;
                this.$session._last_password = undefined;

                this.resetState();

                this.$router.push({name: 'Accueil'});

                if(event.code === 4405 ){
                  window._sketchy_err_salon_plein = true;
                }

                return;
              }
            }

            this.ws_connexion_en_cours = false;
            this.ws_connected = false;

            if (this.e2_countdown_interval !== null) {
              clearInterval(this.e2_countdown_interval);
              this.e2_countdown_interval = null;
            }
          });
        })
        .catch((err) => {
          if (err.response.status === 410 /*GONE*/) {
            // Pour afficher sur la page d'accueil que le salon existe plus
            window._sketchy_err_salon_existe_plus = true;
          }
          this.$router.push({
            name: 'Accueil',
          });
        });
    },

    onRawMessage(blob) {
      if (this.salon_etat === 'e3') {
        blob = new Blob([blob], {type: 'image/png'});
        let reader = new FileReader();

        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this.e3_image = reader.result;
        };
      }
    },

    waitForE2(retries, delay, callback) {
      if ('e2' in this.$refs && this.$refs.e2 !== null && this.$refs.e2 !== undefined) {
        callback();
      } else {
        if (retries > 0) {
          setTimeout(() => {
            this.waitForE2(retries-1, delay, callback);
          }, delay);
        } else {
          console.warn('excedded retries on waitForE2')
        }
      }
    },

    onMessage(msg_type, msg_data) {
      if (msg_type !== 'e4_cur')
        console.log('Réception message =>', msg_type, msg_data);

      switch (msg_type) {
        case 'pl_you_were_kicked':
          this.$session._last_salon = undefined;
          this.$session._last_pseudo = undefined;
          this.$session._last_password = undefined;

          window._sketchy_notif_you_have_been_kicked = this.infosSalon.nom_salon;

          this.$router.push({name: 'Accueil'});
        case 'pl_kicked':
          this.nb_joueurs -= 1;
          if ('playerList' in this.$refs && this.$refs.playerList !== undefined)
            this.$refs.playerList.onMessage(msg_type, msg_data);
          break;
        case 'pl_nick':
          this.nom_joueur = msg_data;
          this.$session._last_pseudo = msg_data;
          this._matomo_setCustomVariable(1, 'Pseudo', msg_data, 'visit');
          break;
        case 'pl_index':
          this.index_joueur = msg_data;
          break;
        case 'pl':
          if ('playerList' in this.$refs && this.$refs.playerList !== undefined)
            this.$refs.playerList.onMessage(msg_type, msg_data);
          this.nb_joueurs = msg_data.length;
          if (!this.play_sound_connect) {
            this.soundReady(-2);
            this.play_sound_connect = true;
          }
          break;
        case 'pl_connect':
          if ('playerList' in this.$refs && this.$refs.playerList !== undefined)
            this.$refs.playerList.onMessage(msg_type, msg_data);
          this.nb_joueurs += 1;
          this.messages_chatbox.push({
            '$t': 'sketchy.page_jeu.chatbox.connexion',
            '$t_params': [msg_data],
            'color': '#009730'
          });
          break;
        case 'pl_disconnect':
          if ('playerList' in this.$refs && this.$refs.playerList !== undefined)
            this.$refs.playerList.onMessage(msg_type, msg_data);
          this.nb_joueurs -= 1;
          break;
        case 'pl_restarted':
          if ('playerList' in this.$refs && this.$refs.playerList !== undefined)
            this.$refs.playerList.onMessage(msg_type, msg_data);

          this.messages_chatbox.push({
            '$t': 'sketchy.page_jeu.chatbox.restart',
            '$t_params': [msg_data],
            'color': '#0066cb'
          });
          break;
        case 'pl_scores':
        case 'pl_gone':
        case 'pl_state':
        case 'pl_back':
        case 'e1_mot_pret':
        case 'e3_mot_pret':
          if ('playerList' in this.$refs && this.$refs.playerList !== undefined)
            this.$refs.playerList.onMessage(msg_type, msg_data);

          if (msg_data === this.nom_joueur)
            this.flag_e3_mot_ok = true;

          break;
        case 's_etat':
          if (this.e2_dessin !== null && this.salon_etat === 'e2' && msg_data === 'e2' && this.e2_countdown === 0 && this.e2_countdown_interval === null) {
            console.info('Reception etat e2 alors que déjà en etat e2 => retransmission du dernier dessin')
            this.sendRawMsg(this.e2_dessin);
          } else if (!(this.salon_etat === 'e2' && msg_data === 'e2' && this.e2_countdown !== null && this.e2_countdown > 0 && this.e2_countdown_interval !== null)) {
            this.e2_countdown = null;
            this.e2_dessin = null;
          }

          let old_etat = this.salon_etat;
          this.salon_etat = msg_data;

          this.$session._last_salon = this.$route.params.salon;

          if ('notificationRejoin' in this.$refs && this.$refs.notificationRejoin !== undefined && '_last_salon' in this.$session.data && this.$session.data._last_salon === this.$route.params.salon)
            this.$refs.notificationRejoin.hide();

          if (this.salon_etat === 'e0') {
            // Afficher "Machin a rejoint la partie" pour soi même
            this.messages_chatbox.push({
              '$t': 'sketchy.page_jeu.chatbox.connexion',
              '$t_params': [this.nom_joueur],
              'color': '#009730',
            });

            // Envoyer la liste des mots bannis au serveur
            if (this.$session._seen_words !== undefined && Array.isArray(this.$session._seen_words) && this.$session._seen_words.length > 0) {
              console.info('La seen words contient des trucs, transmission au serveur =>', this.$session._seen_words);

              let seenWords = [];
              for (let i in this.$session._seen_words)
                if (typeof this.$session._seen_words[i] === 'string')
                  seenWords.push(this.$session._seen_words[i]);

              this.sendMsg('e0_seen_words', seenWords);
            }
          }

          if (this.salon_etat === 'e1') {
            this.e1_mots = null;
            this.e2_mot = null;

            if ('_paq' in window) {
              if (this.nb_joueurs > 0)
                this._matomo_setCustomVariable(2, 'Joueurs', (this.index_joueur + 1) + '/' + this.nb_joueurs, 'visit', false);

              this._matomo_setCustomVariable(4, 'Parties', this.nb_parties + '/' + (this.nb_parties + 1), 'visit');

              this.nb_parties++;
            }

            this.soundStart();

            if (old_etat === 'e0' && this.nb_parties === 1) {
              this._matomo_trackGoal(this.MTM_GOAL_GAME_START);
            }
          }

          if (this.salon_etat === 'e2') {
            this.e2_mot = null;

            // Lecture du son de draw légèrement en retard pour pas qu'il soit trop collé au pop
            setTimeout(this.soundDraw, 400);

            try {
              if (old_etat === null) {
                if ('localStorage' in window && 'getItem' in window.localStorage) {
                  let data = window.localStorage.getItem(LOCAL_STORAGE_E2_DESSIN);
                  if (data !== false && data !== null && data !== undefined && data !== '') {
                    data = JSON.parse(data);
                    if (data !== false && data !== null && data !== undefined && data !== '') {
                      if (data && 'id_salon' in data && data.id_salon === this.$route.params.salon && 'e2_countdown' in data && 'data' in data) {
                        this.waitForE2(15, 100, () => {
                          if (this.$refs.e2) {
                            this.$refs.e2.importData(data.data);
                            this.e2CountdownStart(data.e2_countdown + 2);
                          }
                        });
                      }
                    }
                  }
                }
              }
            }
            catch (err) { console.warn('catch lors de la récup du dessin depuis localstorage =>', err); }
          }

          if (this.salon_etat === 'e3') {
            this.e3_image = null;
            this.flag_e3_mot_ok = false;
            this.soundGuess();

            if ('localStorage' in window && 'removeItem' in window.localStorage) {
              try {
                window.localStorage.removeItem(LOCAL_STORAGE_E2_DESSIN);
              }
              catch (err) { console.warn('catch lors de la suppression du dessin du localstorage =>', err); }
            }
          }

          if (this.salon_etat === 'e4') {
            if (this.lastMotChoisi !== null)
              this.$session.pushSeenWord(this.lastMotChoisi);

            this._matomo_setCustomVariable(4, 'Parties', this.nb_parties + '/' + this.nb_parties, 'visit');

            if (old_etat === 'e3') {
              // Lecture du son de guess légèrement en retard pour pas qu'il soit trop collé au pop
              setTimeout(this.soundGuess, 400);

              this._matomo_trackGoal(this.MTM_GOAL_GAME_FINISH);
            }
          }

          break;
        case 's_rounds_cur':
          this.rounds_cur = msg_data;
          break;
        case 's_rounds_total':
          this.rounds_total = msg_data;
          break;
        case 'e1_mot':
          if ('e1AttenteMot' in this.$refs && this.$refs.e1AttenteMot !== undefined)
            this.$refs.e1AttenteMot.onMessage(msg_type, msg_data);
          else if (this.salon_etat === 'e1')
            setTimeout(() => {
              this.onMessage(msg_type, msg_data);
            }, 40);
          break;
        case 'e1_mots':
          this.e1_mots = msg_data;
          break;
        case 'e2_mot':
          this.e2_mot = msg_data;
          break;
        case 'e2_countdown':
          //console.log('this.e2_countdown =', this.e2_countdown);
          //console.log('this.e2_countdown_interval =', this.e2_countdown_interval);

          if (this.e2_countdown === null || this.e2_countdown === 0 || this.e2_countdown_interval === null)
            this.e2_countdown = msg_data;

          break;
        case 'e2_expected_countdown':
          this.e2_expected_countdown = msg_data;

          if (this.e2_expected_countdown !== null && this.e2_countdown !== null) {
            if (Math.abs(this.e2_expected_countdown - this.e2_countdown) > 3) {
              this.flag_e2_dispatch_countdown = true;
            }
          }
          break;
        case 'e2_player_countdown':
          if ('playerList' in this.$refs && this.$refs.playerList !== undefined)
            this.$refs.playerList.onMessage(msg_type, msg_data);
          break;
        case 'e3_auteur':
          this.e3_auteur = msg_data;
          break;
        case 'e3_mot':
          if ('e3DevinerMotFormulaire' in this.$refs && this.$refs.e3DevinerMotFormulaire !== undefined)
            this.$refs.e3DevinerMotFormulaire.onMessage(msg_type, msg_data);
          else if (this.salon_etat === 'e3')
            setTimeout(() => {
              this.onMessage(msg_type, msg_data);
            }, 40);
          break;
        case 'e4_mots':
          this.e4_mots = msg_data;
          break;
        case 'e4_auteurs':
          this.e4_auteurs = msg_data;
          break;
        case 'e4_mots_auteurs':
          this.e4_mots_auteurs = msg_data;
          break;
        case 'e4_mots_scores':
          this.e4_mots_scores = msg_data;
          break;
        case 'wait_password':
          this.waitPassword = true;
          break;
        case 'pass_invalid':
          this.passInvalid = true;
          break;
        case 'user_exist':
          this.waitPassword = false;
          this.passInvalid = false;
          this.userExist = true;
          sketchy_ws.close();
          break;
        case 'chatbox_msg':
          this.messages_chatbox.push(msg_data);
          break;
        case 'e4_cur':
        case 'e4_cur_depop':
          if ('etat4Fin' in this.$refs && this.$refs.etat4Fin !== undefined)
            this.$refs.etat4Fin.onMsg(msg_type, msg_data);
          else if (this.salon_etat === 'e4')
            setTimeout(() => {
              this.onMessage(msg_type, msg_data);
            }, 40);
          break;
        case 'e4_restarted':
          window._sketchy_pseudo = this.nom_joueur;
          window._sketchy_password = msg_data.pw === '' ? null : msg_data.pw;

          this.resetState();

          this.$router.push({name: 'Salon', params: {salon: msg_data.id}});
          this.$nextTick(() => {
            this.tryConnect();
          });
          break;
        case 'init_game_options':
          this.gameOptions = msg_data;

          if ('gameOptions' in this.$refs && this.$refs.gameOptions !== undefined)
            this.$refs.gameOptions.onMessage(msg_type, this.gameOptions);
          else if (this.salon_etat === 'e0')
            setTimeout(() => {
              this.onMessage(msg_type, this.gameOptions);
            }, 40);
          break;
        case 'game_options':
          this.gameOptions = msg_data;

          if (!this.isHost) {
            if ('gameOptions' in this.$refs && this.$refs.gameOptions !== undefined)
              this.$refs.gameOptions.onMessage(msg_type, this.gameOptions);
            else if (this.salon_etat === 'e0')
              setTimeout(() => {
                this.onMessage(msg_type, this.gameOptions);
              }, 40);
          }
          break;
      }
    },

    sendMsg(msg_type, msg_data) {
      if (msg_type === 'chatbox_msg' || msg_type === 'e3_mot' || msg_type === 'e1_mot') {
        this.interact();
      }

      if (msg_type === 'wait_password') {
        this.$session._last_password = msg_data;
        this.passInvalid = false;
      }

      sketchy_ws.send(msg_type, msg_data);
    },

    sendRawMsg(data) {
      sketchy_ws.sendRaw(data);
    },

    chatboxPush(msg) {
      this.messages_chatbox.push(msg);
    },

    e2CountdownStart(v) {
      this.e2_countdown = v;

      if (this.e2_countdown_interval !== null)
        clearInterval(this.e2_countdown_interval);

      this.e2_countdown_interval = setInterval(() => {
        if (this.e2_countdown === 0) {
          clearInterval(this.e2_countdown_interval);
          this.e2_countdown_interval = null;
          this.e2CountdownFinished();
          return;
        }

        this.e2_countdown -= 1;

        if (this.flag_e2_dispatch_countdown) {
          this.sendMsg('e2_current_countdown', this.e2_countdown);
        }

        if (this.e2_countdown >= 0 && this.e2_countdown % 4 === 0) {
          if ('e2' in this.$refs && this.$refs.e2 !== null && this.$refs.e2 !== undefined) {
            if ('localStorage' in window && 'setItem' in window.localStorage) {
              try {
                window.localStorage.setItem(LOCAL_STORAGE_E2_DESSIN, JSON.stringify({
                  id_salon: this.$route.params.salon,
                  e2_countdown: this.e2_countdown,
                  data: this.$refs.e2.exportData(),
                }));
              }
              catch (err) { console.warn('catch lors de la sauvegarde du dessin en localstorage =>', err); }
            }
          }
        }
      }, 1000);
    },

    e2CountdownFinished() {
      if ('e2' in this.$refs && this.$refs.e2 !== undefined)
        this.$refs.e2.countdownFinished();
    },

    e2DessinFini(data) {
      this.e2_dessin = data;
      this.sendRawMsg(data);
    },

    soundReady(n) {
      if ('soundManager' in this.$refs && this.$refs.soundManager !== undefined && this.$refs.soundManager !== null && this.$refs.soundManager.ready) {
        this.$refs.soundManager.ready(n);
      }
    },

    soundStart() {
      if ('soundManager' in this.$refs && this.$refs.soundManager !== undefined && this.$refs.soundManager !== null && this.$refs.soundManager.start) {
        this.$refs.soundManager.start();
      }
    },

    soundDraw() {
      if ('soundManager' in this.$refs && this.$refs.soundManager !== undefined && this.$refs.soundManager !== null && this.$refs.soundManager.guess) {
        this.$refs.soundManager.draw();
      }
    },

    soundGuess() {
      if ('soundManager' in this.$refs && this.$refs.soundManager !== undefined && this.$refs.soundManager !== null && this.$refs.soundManager.draw) {
        this.$refs.soundManager.guess();
      }
    },

    exportHtmlClicked() {
      this._matomo_trackGoal(this.MTM_GOAL_GAME_DOWNLOAD);
    },
  },

  watch: {
    'e2_countdown': {
      immediate: true,
      handler: function (v) {
        if (v !== null)
          if (this.e2_countdown_interval === null)
            this.e2CountdownStart(v);
      },
    },

    '$route.params.salon': function () {
      this.resetState();
    },
  },

  computed: {
    isHost() {
      return this.index_joueur === 0;
    },

    prevJoueurIndex() {
      if (this.index_joueur === null) return null;

      if (this.index_joueur > 0)
        return this.index_joueur - 1;

      if (this.nb_joueurs === 0) return null
      return this.nb_joueurs - 1;
    },

    prevJoueurNom() {
      let i = this.prevJoueurIndex;
      if (i !== null && 'playerList' in this.$refs && this.$refs.playerList !== undefined) {
        return this.$refs.playerList.getJoueurNom(i);
      }
      return null;
    },

    nextJoueurIndex() {
      if (this.index_joueur === null) return null;
      if (this.nb_joueurs === 0) return null

      return (this.index_joueur + 1) % this.nb_joueurs;
    },

    nextJoueurNom() {
      let i = this.nextJoueurIndex;
      if (i !== null && 'playerList' in this.$refs && this.$refs.playerList !== undefined)
        return this.$refs.playerList.getJoueurNom(i);
      return null;
    },

    nb_rounds() {
      if (this.nb_joueurs <= 2) return 1;

      if ('longer_games' in this.gameOptions && this.gameOptions.longer_games)
        return this.nb_joueurs - 1;
      else
        return (this.nb_joueurs - (this.nb_joueurs % 2)) / 2;
    },

    duree_partie_total_secs() {
      let drawing_time = ('drawing_time' in this.gameOptions && this.gameOptions.drawing_time) ?
        parseInt(this.gameOptions.drawing_time) : 45;

      return this.nb_rounds * drawing_time /*temps des dessins*/ +
        (this.nb_rounds+1) * 10 /*temps de deviner les mots*/ +
        Math.round(this.nb_rounds * 2.5) * this.nb_joueurs /*temps de visualiser les dessins*/
      ;
    },

    duree_partie_minutes() {
      return (this.duree_partie_total_secs - this.duree_partie_total_secs % 60) / 60;
    },

    duree_partie_secs() {
      return this.duree_partie_total_secs % 60;
    },
  },

  components: {
    NotificationRejoin: () => import('./components/global/nav/NotificationRejoin'),
    DrapeauFr: () => import('@/resources/images/Icone_pays_fr'),
    DrapeauUsaUk: () => import('@/resources/images/Icone_pays_usa_uk'),

    Chatbox: () => import('./components/salon/Chatbox'),
    PlayerList: () => import('./components/salon/PlayerList'),
    BarreRounds: () => import('./components/salon/BarreRounds'),

    Etat_NonConnecte: () => import('./components/salon/etats/Etat_NonConnecte'),
    Etat1AttenteMot: () => import('./components/salon/etats/Etat1AttenteMot'),
    Etat2AttenteDessin: () => import('./components/salon/etats/Etat2AttenteDessin'),
    Etat3DevinerMot: () => import('./components/salon/etats/Etat3DevinerMot'),
    E3DevinerMotFormulaire: () => import('./components/salon/E3DevinerMotFormulaire'),
    Etat4Fin: () => import('./components/salon/etats/Etat4Fin'),
    BoutonPlay: () => import('./components/global/boutons/BoutonPlay'),
    BoutonDl: () => import('./components/global/boutons/BoutonDl'),

    E0BoxPartageLien: () => import('./components/salon/E0BoxPartageLien'),
    E0GameOptions: () => import('./components/salon/E0GameOptions'),
    E2Timer: () => import('./components/salon/E2Timer'),

    SoundManager: () => import('./components/salon/SoundManager'),
  },
}
</script>
